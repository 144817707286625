<template>
  <div id="page">
    <Header />
    <Footer />
    <PageTitle :title="'Event Details'" />
    <div class="page-title-clear"></div>
    <div class="page-content">
      <div class="card card-style">
        <div class="content">
          <div class="d-flex">
            <div class="mt-1">
              <p class="color-highlight font-600 mb-n1">
                Palo Alto, California
              </p>
              <h1>Enabled Devs</h1>
            </div>
            <div class="ms-auto">
              <img
                src="images/pictures/34s.jpg"
                width="60"
                class="rounded-xl"
              />
            </div>
          </div>

          <div class="row mb-0 mt-4">
            <h5 class="col-4 text-start font-15">Invoice</h5>
            <h5 class="col-8 text-end font-14 opacity-60 font-400">#13241</h5>

            <h5 class="col-4 text-start font-15">Bill To</h5>
            <h5 class="col-8 text-end font-14 opacity-60 font-400">John Doe</h5>
            <h5 class="col-4 text-start font-15">Date</h5>
            <h5 class="col-8 text-end font-14 opacity-60 font-400">
              15th June 2025
            </h5>

            <h5 class="col-4 text-start font-15">Address</h5>
            <h5 class="col-8 text-end font-14 opacity-60 font-400 pb-4">
              PO Box 13451 Palo Alto Street California, Flat 1235
            </h5>
          </div>

          <div class="divider"></div>

          <div class="d-flex mb-3">
            <div>
              <img
                src="images/pictures/17s.jpg"
                width="110"
                class="rounded-s shadow-xl"
              />
            </div>
            <div class="ps-3 w-100">
              <p class="mb-0 color-highlight">1x Item</p>
              <h1>$2.150</h1>
              <h5 class="font-500">
                Your awesome product or service name goes here
              </h5>
            </div>
          </div>

          <div class="d-flex mb-3">
            <div class="d-flex mb-3"></div>
            <div class="card-bottom pe-3 mb-3">
              <div class="float-end">
                <a
                  href="#"
                  @click="menuOpen('menu-share')"
                  class="btn btn-s gradient-green color-white font-600 rounded-s"
                  >View Your Ticket</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
    <div
      id="menu-share"
      class="menu menu-box-modal rounded-m"
      data-menu-height="495"
      data-menu-width="370"
    >
      <div class="card card-style">
        <div class="text-center pt-4 pb-4">
          <h2>Ticket Of Meuseum TMII</h2>

          <ul class="icon-list ps-3 font-600">
            <li>Distrik Shighashina bersebelahan Wall Maria</li>
            <li>Room 002</li>
            <li>Sesi 14.00 - 16.00</li>
          </ul>

          <img
            width="250"
            class="qr-image generate-qr-auto mx-auto polaroid-effect shadow-l mb-3"
            src=""
          />
          <p class="text-center font-10 pt-2 mb-0">
            Scanning this QR Will take you to this page.
          </p>
        </div>
      </div>
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import PageTitle from "@/components/PageTitle";
import { init_template, menuOpen, menuClose } from "@/appkit.js";
export default {
  components: { Header, Footer, PageTitle },
  data() {
    return {
      event: {},
    };
  },
  mounted() {
    init_template();
  },
  methods: {
    menuOpen,
    menuClose,
  },
};
</script>
